import permissions from './permissions';
import {checkOrg} from './../utils/checkOrg'

export default function(backendPermission, result = [], orgType) {
  // default permissions
  if (result.length === 0) {
    result.push(permissions.taskPage);
    result.push(permissions.manualPage);
    result.push(permissions.faqPage);
    result.push(permissions.homePage);
    result.push(permissions.instructionPage);
  }
  if (backendPermission === 'ES_SCOPE_GOD') {
    if (checkOrg() === true) {
      setPermissions(result, [
        permissions.admin,
        permissions.journalPage_columnBin,
        permissions.journalPage_columnLocations,
        permissions.MIO_EMPLOYEE,
        permissions.DDO_EMPLOYEE,
        permissions.contingentPage,
        permissions.contingentSectionPage,
        permissions.contingentSchoolPage,
        permissions.groupPage,
        permissions.colleagueReportPage,
        permissions.DDOReportPage,
        permissions.sectionReportPage,
        permissions.schoolReportPage,
        permissions.foodReportPage
      ]);
    } else {
      setPermissions(result, [
        permissions.admin,
        permissions.journalPage_columnBin,
        permissions.journalPage_columnLocations,
        permissions.MIO_EMPLOYEE,
        permissions.DDO_EMPLOYEE,
        permissions.contingentPage,
        permissions.groupPage,
        permissions.DDOReportPage,
      ]);
    }
  } else if (backendPermission === 'CAN_READ_REQUEST_FORM') {
    setPermissions(result, [permissions.journalPage, permissions.statementViewPage]);
  } else if (backendPermission === 'CAN_READ_REQUEST_FORM_ARCHIVE') {
    setPermissions(result, [permissions.archivePage, permissions.statementViewPage]);
  } else if (backendPermission === 'CAN_EXECUTE_STAT_REPORT') {
    setPermissions(result, [
      permissions.monitoringTablePage,
      permissions.monitoringChartsPage
    ]);
  } else if (backendPermission === 'CAN_MANAGE_DDM') {
    setPermissions(result, [permissions.handbookPage, permissions.monitoringChartsPage]);
  } else if (backendPermission === 'CAN_READ_SERVICE_PROVIDER') {
    setPermissions(result, [
      permissions.serviceProviderViewPage,
      permissions.serviceProvidersPage
    ]);
  } else if (backendPermission === 'CAN_MANAGE_USERS') {
    setPermissions(result, [
      permissions.administrationPage,
      permissions.journalPage_columnBin,
      permissions.journalPage_columnLocations
    ]);
  } else if (backendPermission === 'CAN_WRITE_REQUEST_FORM') {
    setPermissions(result, [
      permissions.journalPage,
      permissions.statementPage,
      permissions.statementViewPage
    ]);
  } else if (backendPermission === 'CAN_WRITE_SERVICE_PROVIDER') {
    setPermissions(result, [
      permissions.serviceProvidersPage,
      permissions.serviceProviderPage,
      permissions.serviceProviderViewPage
    ]);
  } else if (backendPermission === 'CAN_VIEW_TASKS') {
    setPermissions(result, [
      permissions.myTaskPage
    ]);
  } else if (backendPermission === 'SUO_SCOPE_REGION' && checkOrg()) {
    setPermissions(result, [
      permissions.DDOReportPage,
      permissions.schoolReportPage,
      permissions.sectionReportPage
    ]);
  } else if (orgType == '01' && backendPermission === 'CAN_VIEW_CONTINGENT') {
      setPermissions(result, [
        permissions.contingentPage
      ]);
  } else if (orgType == '01' && backendPermission === 'CAN_VIEW_STUDY_GROUPS') {
    setPermissions(result, [
      permissions.groupPage
    ]);
  } else if (backendPermission === 'CAN_VIEW_STAFF') {
    setPermissions(result, [
      permissions.colleaguePage
    ]);
  } else if ((orgType == '02' && backendPermission === 'CAN_VIEW_CONTINGENT_SCHOOL_STUDENT') && checkOrg()) { 
    setPermissions(result, [
      permissions.contingentSchoolPage
    ]);
  } else if ((orgType == '10' && backendPermission === 'CAN_VIEW_CONTINGENT_SECTION_VISITOR') && checkOrg()) {
    setPermissions(result, [
      permissions.contingentSectionPage
    ]);
  } else if ((orgType == '01' && backendPermission === 'CAN_VIEW_VISIT_LOG') || backendPermission === 'SUO_SCOPE_REGION') { 
    setPermissions(result, [
      permissions.DDOReportPage,
    ]);
  } else if ((orgType == '02' && backendPermission === 'CAN_VIEW_VISIT_LOG') && checkOrg()) { 
    setPermissions(result, [
      permissions.schoolReportPage,
    ]);
  } else if ((orgType == '10' && backendPermission === 'CAN_VIEW_VISIT_LOG') && checkOrg()) {
      setPermissions(result, [
        permissions.sectionReportPage,
    ]);
  } else if ((backendPermission === 'CAN_VIEW_VISIT_LOG_STAFF') && checkOrg()) {
    setPermissions(result, [
      permissions.colleagueReportPage, 
    ]);
  } else if ((orgType == '02' && backendPermission === 'CAN_VIEW_FREE_MEAL_INFO') && checkOrg()) {
    setPermissions(result, [
      permissions.foodReportPage, 
    ]);
  } else if (backendPermission === 'CAN_VIEW_PO_VISIT_LOG_MONITORING') {
    setPermissions(result, [
      permissions.monitoringVisitPage,
    ]);
  }
}
function setPermissions(result = [], permissions = []) {
  for (const permission of permissions) {
    if (!result.includes(permission)) {
      result.push(permission);
    }
  }
}
