import React from 'react';
import moment from 'moment';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import { Color } from '../ThemeProvider/ThemeStyle';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};

export const ReportColumns = ({ t, days, open, cause, lang }) => {
  let newDays = []
  days && days.forEach(item => {
    newDays.push({
      Header: String(moment(item).format('DD')),
      accessor: item,
      Cell: ({original}) => {
        let currentVisited = null;
        let text = null;
        if (original?.visitsInfo) {
          original.visitsInfo.forEach((visitInfo) => {
            if (visitInfo.logDate === item) {
              currentVisited = visitInfo;
              currentVisited['userID'] = original.childId ? original.childId : original.staffId;
            }
          })
        }
        cause.forEach((item) => {
          if (item.code === currentVisited?.['absenceReason']) {
            if (lang == 'ru') {
              text = item?.fisrt_letter_ru
            } else {
              text = item?.first_letter_kk
            }
          }
        })
        return (
          <>
            {
              currentVisited?.isVisited === false && <div
                style={{
                  backgroundColor:
                    currentVisited.absenceReason && Color.injury  ||
                    Color.secondary,
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
                onClick={() => {if (open) {
                  open(currentVisited)
                }}}
              >
                {
                  text ? <span style={{ transform: 'scale(0.45)', display: 'block', color: '#000', minWidth: '2px' }}> {text} </span>
                    : <span style={{ transform: 'scale(0.45)', display: 'block', color: Color.secondary, minWidth: '2px',  }}> - </span>
                }
              </div>
            }
            {
              currentVisited?.isVisited === true &&
              <div
                style={{
                  backgroundColor: Color.bgColorSuccess,
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
                onClick={() => {if (open) {
                  open(currentVisited)
                }}}
              >
                <span style={{ transform: 'scale(0.55)', display: 'block' }}> + </span>
              </div>
            }
          </>
        )
      },
      Filter: <span></span>,
    })
  });
  let daysTable = [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 100,
      width: 100,
      filterable: false,
      Cell: (row) => row.original.iin && row.index + 1,
    },
    {
      Header: 'ID',
      accessor: 'childId',
      Filter: <FilterInputField name="childId" type="number" />,
      maxWidth: 150,
      width: 150,
      Cell: ({ original}) => {
        return (
          <div>
            {original.childId && original.childId}
            {original.staffId && original.staffId}
          </div>
        )
      },
    },
    {
      Header: t('pageReport_fullName'),
      accessor: 'fullName',
      Filter: <FilterInputField name="fullName" />,
      Cell: ({ original}) => {
        return (
          <div style={{fontWeight: original.totalFinish ? 600 : null}}>
            {original.surname} {original.name} {original?.middlename}
          </div>
        )
      },
      maxWidth: 470,
      width: 470,
    },
    ...newDays,
    {
      Header: t('pageReport_visitedDays'),
      accessor: 'visitedDays',
      Filter: <span></span>,
      maxWidth: 250,
      width: 250,
      style: styles
    },
    {
      Header: <div>
        <p className='reportBorderBottom'>{t('pageReport_missedDays')}</p>
        <div style={{ display: 'flex' }}>
          <p className="reportBorderRight reportCauseTable" style={{width: '15%', maxWidth: '15%'}}>{t('pageReport_reference')}</p>
          <p className="reportBorderRight reportCauseTable" style={{width: '30%', maxWidth: '30%'}}>{t('pageReport_applicationLeave')}</p>
          <p className="reportBorderRight reportCauseTable" style={{width: '30%', maxWidth: '30%'}}>{t('pageReport_statementParents')}</p>
          <p className="reportCauseTable" style={{width: '17%', maxWidth: '20%'}}>{t('pageReport_withReason')}</p>
        </div>
      </div>,
      accessor: 'unvisitedDaysWithoutReason',
      Filter: <span></span>,
      maxWidth: 2100,
      width: 2100,
      Cell: ({ original}) => {
        return (
          <div style={{ display: 'flex' }}>
            <p className='reportBorderRight reportCauseTable' style={{width: '15%', maxWidth: '15%'}}>{original.unvisitedDaysWithReasonReference}</p>
            <p className='reportBorderRight reportCauseTable' style={{width: '30%', maxWidth: '30%'}}>{original.unvisitedDaysWithReasonLeaveApplication}</p>
            <p className='reportBorderRight reportCauseTable' style={{width: '30%', maxWidth: '30%'}}>{original.unvisitedDaysWithReasonParentStatement}</p>
            <p className='reportCauseTable' style={{width: '17%', maxWidth: '20%'}}>{original.unvisitedDaysWithoutReason}</p>
          </div>
        )
      },
      style: styles
    },
    {
      Header: t('pageReport_childrenDays'),
      accessor: 'childDays',
      Filter: <span></span>,
      maxWidth: 250,
      width: 250,
      style: styles
    },
    // {
    //   Header: t('pageReport_sum'),
    //   accessor: 'visitSumma',
    //   Filter: <span></span>,
    //   maxWidth: 350,
    //   width: 350,
    //   Cell: ({ original}) => {
    //     let price       = original.visitSumma.toFixed(2),
    //       price_sep2   = price.replace(/(\D)/g, ","),
    //       price_sep3   = price_sep2.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    //     return (
    //       <div>
    //         {price_sep3}
    //       </div>
    //     )
    //   },
    //   style: styles
    // },
  ];
  return daysTable
};
