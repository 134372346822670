import LoginPage from '../pages/LoginPage/LoginPage';
import HomePage from '../pages/HomePage/HomePage';
import permissions from './permissions';
import paths from './paths';
import StatementPage from '../pages/StatementPage/StatementPage';
import JournalPage from '../pages/JournalPage/JournalPage';
import MonitoringPage from '../pages/MonitoringPage/MonitoringPage';
import HandbookPage from '../pages/HandbookPage/HandbookPage';
import ServiceProvidersPage from '../pages/ServiceProvidersPage/ServiceProvidersPage';
import ServiceProviderPage from '../pages/ServiceProviderPage/ServiceProviderPage';
import AdministrationPage from '../pages/AdministrationPage/AdministrationPage';
import InstructionPage from '../pages/InstructionPage/InstructionPage';
import ArchivePage from '../pages/ArchivePage/ArchivePage';
import ContactSupportPage from '../pages/ContactSupportPage/ContactSupportPage';
import ManualPage from '../pages/ManualPage/ManualPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import MyTaskPage from '../pages/MyTaskPage/MyTaskPage';
import TaskPage from '../pages/MyTaskPage/TaskPage/TaskPage';
import QueuePage from '../pages/QueuePage/QueuePage';
import QueueByName from '../pages/QueuePage/QueueByName';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ChangePassword from '../pages/ChangePassword/ChangePassword';
import KezekBulletinTablePage from '../pages/KezekBulletinTablePage/KezekBulletinTablePage';
import KezekDirectionTablePage from '../pages/KezekDirectionTablePage/KezekDirectionTablePage';
import KezekContingentPage from '../pages/KezekContingentPage/KezekContingentPage';
import KezekPassportPage from '../pages/KezekPassportPage/KezekPassportPage';
import KezekRequestPage from '../pages/KezekRequestPage/KezekRequestPage';
import KezekStopListPage from '../pages/KezekStopListPage/KezekStopListPage';
import KezekArchivePage from '../pages/KezekArchivePage/KezekArchivePage';
import KezekBulletinPage from '../pages/KezekBulletinPage/KezekBulletinPage';
import KezekDirectionPage from '../pages/KezekDirectionPage/KezekDirectionPage';
import ContingentPage from '../pages/ContingentPage/ContingentPage';
import ContingentPassportPage from '../pages/ContingentPassportPage/ContingentPassportPage';
import GroupsTablePage from '../pages/GroupsTablePage/GroupsTablePage';
import GroupPassportPage from '../pages/GroupPassportPage/GroupPassportPage';
import GroupListPage from '../pages/GroupListPage/GroupListPage';
import ColleaguesPage from '../pages/ColleaguesPage/ColleaguesPage';
import ColleaguesPassportPage from '../pages/ColleaguesPage/ColleaguesPassportPage'
import ContingentSchoolPage from '../pages/ContingentSchoolPage/ContingentSchoolPage';
import ContingentSchoolPassport from '../pages/ContingentSchoolPage/ContingentSchoolPassportPage';
import ContingentSectionPage from '../pages/ContingentSectionPage/ContingentSectionPage';
import ContingentSectionPassport from '../pages/ContingentSectionPage/ContingentSectionPassportPage';
import ColleaguesReportPage from '../pages/ColleaguesPage/ColleaguesReportPage'
import ContingentSchoolReport from '../pages/ContingentSchoolPage/ContingentSchoolReportPage'
import ContingentSectionReport from '../pages/ContingentSectionPage/ContingentSectionReportPage'
import ContingentDDOReport from '../pages/ContingentPage/ContingentDDOReportPage'
import ClassPage from '../pages/ClassPage/ClassPage';
import ClassPassportPage from '../pages/ClassPassportPage/ClassPassportPage';
import ClassListPage from '../pages/ClassListPage/ClassListPage';
import ContingentFoodReport from '../pages/ContingentSchoolPage/ContingentSchoolFoodReportPage';
import MonitoringVisitPage from '../pages/MonitoringVisitPage/MonitoringVisitPage';
import MonitoringVisitListPage from '../pages/MonitoringVisitPage/MonitoringVisitListPage';

const routerProps = {
  loginPage: {
    path: paths.loginPage,
    component: LoginPage,
    withoutAuth: true,
    withoutHeader: true
  },
  changePass: {
    path: paths.changePass,
    component: ChangePassword,
    withoutHeader: true,
    withoutSidebar: true
  },
  resetPassPage: {
    path: paths.resetPassPage,
    component: ResetPassword,
    withoutAuth: true,
    withoutHeader: true
  },
  homePage: {
    path: paths.homePage,
    component: HomePage,
    permissions: [permissions.homePage]
  },
  monitoringVisitPage: {
    path: paths.monitoringVisit,
    component: MonitoringVisitPage,
    permissions: [permissions.monitoringVisitPage]
  },
  monitoringVisitListPage: {
    path: paths.monitoringVisitList,
    component: MonitoringVisitListPage,
    permissions: [permissions.monitoringVisitPage]
  },
  /*archivePage: {
    path: paths.archivePage,
    component: ArchivePage,
    permissions: [permissions.archivePage]
  },
  instructionPage: {
    path: paths.instructionPage,
    component: InstructionPage,
    permissions: [permissions.instructionPage]
  },
  statementPage: {
    path: paths.statementPage,
    component: StatementPage,
    permissions: [permissions.statementPage]
  },
  statementViewPage: {
    path: paths.statementViewPage,
    component: StatementPage,
    permissions: [permissions.statementViewPage]
  },
  journalPage: {
    path: paths.journalPage,
    component: JournalPage,
    permissions: [permissions.journalPage]
  },
  monitoringTablePage: {
    path: paths.monitoringTablePage,
    component: MonitoringPage,
    permissions: [permissions.monitoringTablePage]
  },
  monitoringChartsPage: {
    path: paths.monitoringChartsPage,
    component: MonitoringPage,
    permissions: [permissions.monitoringChartsPage]
  },
  handbookPage: {
    path: paths.handbookPage,
    component: HandbookPage,
    permissions: [permissions.handbookPage]
  },
  serviceProvidersPage: {
    path: paths.serviceProvidersPage,
    component: ServiceProvidersPage,
    permissions: [permissions.serviceProvidersPage]
  },
  serviceProviderPage: {
    path: paths.serviceProviderPage,
    component: ServiceProviderPage,
    permissions: [permissions.serviceProviderPage]
  },
  serviceProviderViewPage: {
    path: paths.serviceProviderViewPage,
    component: ServiceProviderPage,
    permissions: [permissions.serviceProviderViewPage]
  }, */
  administrationPage: {
    path: paths.administrationPage,
    component: AdministrationPage,
    permissions: [permissions.administrationPage]
  },
  /*contactSupportPage: {
    path: paths.contactSupportPage,
    component: ContactSupportPage,
    withoutAuth: true
  },
  manualPage: {
    path: paths.manualPage,
    component: ManualPage,
    permissions: [permissions.manualPage]
  },
  faqPage: {
    path: paths.faqPage,
    component: FaqPage,
    permissions: [permissions.faqPage],
    withoutAuth: true
  },
  myTaskPage: {
    path: paths.myTaskPage,
    component: MyTaskPage,
    permissions: [permissions.myTaskPage]
  },
  taskPage: {
    path: paths.taskPage,
    component: TaskPage,
    permissions: [permissions.taskPage]
  },
  queueTab1Page: {
    path: paths.queueTab1Page,
    component: QueuePage,
    permissions: [permissions.CAN_VIEW_QUEUE]
  },
  queueTab2Page: {
    path: paths.queueTab2Page,
    component: QueuePage,
    permissions: [permissions.CAN_VIEW_QUEUE]
  },
  queueByName: {
    path: paths.queueByName,
    component: QueueByName,
    permissions: [permissions.CAN_VIEW_QUEUE]
  },
  kezekBulletinTable: {
    path: paths.kezekBulletinTable,
    component: KezekBulletinTablePage,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  kezekBulletin: {
    path: paths.kezekBulletin,
    component: KezekBulletinPage,
    permissions: [permissions.DDO_EMPLOYEE, permissions.MIO_EMPLOYEE]
  },
  kezekDirectionTable: {
    path: paths.kezekDirectionTable,
    component: KezekDirectionTablePage,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  kezekDirection: {
    path: paths.kezekDirection,
    component: KezekDirectionPage,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  kezekContingent: {
    path: paths.kezekContingent,
    component: KezekContingentPage,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  kezekPassport: {
    path: paths.kezekPassport,
    component: KezekPassportPage,
    permissions: [permissions.DDO_EMPLOYEE]
  },
  kezekRequest: {
    path: paths.kezekRequest,
    component: KezekRequestPage,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  kezekStopList: {
    path: paths.kezekStopList,
    component: KezekStopListPage,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  kezekArchive: {
    path: paths.kezekArchive,
    component: KezekArchivePage,
    permissions: [permissions.MIO_EMPLOYEE]
  },
  contingentCandidatePage: {
    path: paths.contingentCandidatePage,
    component: ContingentPage,
    permissions: [permissions.contingentPage]
  },
  contingentEnrolledPage: {
    path: paths.contingentEnrolledPage,
    component: ContingentPage,
    permissions: [permissions.contingentPage]
  },
  contingentExitedPage: {
    path: paths.contingentExitedPage,
    component: ContingentPage,
    permissions: [permissions.contingentPage]
  },
  groupsTabelPage: {
    path: paths.groupsTabelPage,
    component: GroupsTablePage,
    permissions: [permissions.groupPage]
  },
  groupPage: {
    path: paths.groupPage,
    component: GroupPassportPage,
    permissions: [permissions.groupPage]
  },
  groupListPage: {
    path: paths.groupListPage,
    component: GroupListPage,
    permissions: [permissions.groupPage]
  },
  classPage: {
    path: paths.classPage,
    component: ClassPage,
    permissions: [permissions.contingentSchoolPage]
  },
  classAddPage: {
    path: paths.classAddPage,
    component: ClassPassportPage,
    permissions: [permissions.contingentSchoolPage]
  },
  classItemPage: {
    path: paths.classItemPage,
    component: ClassListPage,
    permissions: [permissions.contingentSchoolPage]
  },

  contingentItemPage: {
    path: paths.contingentItemPage,
    component: ContingentPassportPage,
    permissions: [permissions.contingentPage]
  },
  employessPage: {
    path: paths.employeesPage,
    component: ColleaguesPage,
    permissions: [permissions.colleaguePage]
  },
  dismissedPage: {
    path: paths.dismissedPage,
    component: ColleaguesPage,
    permissions: [permissions.colleaguePage]
  },
  colleaguesPassportPage: {
    path: paths.colleaguesPassportPage,
    component: ColleaguesPassportPage,
    permissions: [permissions.colleaguePage]
  },
  colleaguesPassportUpdatePage: {
    path: paths.colleaguesPassportUpdatePage,
    component: ColleaguesPassportPage,
    permissions: [permissions.colleaguePage]
  },
  retiredPage: {
    path: paths.contingentRetiredPage,
    component: ContingentSchoolPage,
    permissions: [permissions.contingentSchoolPage]
  },
  pupilsPage: {
    path: paths.contingentPupilsPage,
    component: ContingentSchoolPage,
    permissions: [permissions.contingentSchoolPage]
  },
  contingentSchoolPage: {
    path: paths.contingentSchoolPage,
    component: ContingentSchoolPassport,
    permissions: [permissions.contingentSchoolPage]
  },
  contingentSchoolItemPage: {
    path: paths.contingentSchoolItemPage,
    component: ContingentSchoolPassport,
    permissions: [permissions.contingentSchoolPage],
  },
  studentPage: {
    path: paths.contingentStudentPage,
    component: ContingentSectionPage,
    permissions: [permissions.contingentSectionPage],
  },
  departPage: {
    path: paths.contingentDepartPage,
    component: ContingentSectionPage,
    permissions: [permissions.contingentSectionPage],
  },
  contingentSectionPage: {
    path: paths.contingentSectionPage,
    component: ContingentSectionPassport,
    permissions: [permissions.contingentSectionPage],
  },
  contingentSectionItemPage: {
    path: paths.contingentSectionItemPage,
    component: ContingentSectionPassport,
    permissions: [permissions.contingentSectionPage],
  },
  colleaguesReportPage: {
    path: paths.colleaguesReportPage,
    component: ColleaguesReportPage,
    permissions: [permissions.colleagueReportPage, permissions.MIO_EMPLOYEE],
  },
  contingentReportSchoolPage: {
    path: paths.contingentReportSchoolPage,
    component: ContingentSchoolReport,
    permissions: [permissions.schoolReportPage, permissions.MIO_EMPLOYEE],
  },
  contingentReportSectionPage: {
    path: paths.contingentReportSectionPage,
    component: ContingentSectionReport,
    permissions: [permissions.sectionReportPage, permissions.MIO_EMPLOYEE],
  },*/
  contingentReportDDOPage: {
    path: paths.contingentReportDDOPage,
    component: ContingentDDOReport,
    permissions: [permissions.DDOReportPage, permissions.MIO_EMPLOYEE],
  },
 /* contingentReportFoodPage: {
    path: paths.contingentReportFoodPage,
    component: ContingentFoodReport,
    permissions: [permissions.schoolReportPage, permissions.MIO_EMPLOYEE],
  },*/
};

export const headerPaths = Object.values(routerProps)
  .filter(item => !item.withoutHeader)
  .map(item => item.path);

export const sidebarPaths = Object.values(routerProps)
  .filter(item => !item.withoutSidebar)
  .map(item => item.path);

export default routerProps;
