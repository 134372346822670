import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    colleagues_title: 'Персонал',
    colleagues_employees: 'Сотрудники',
    colleagues_dismissed: 'Уволенные',
    colleagues_employeesAdd: 'Добавить',
    colleagues_modalEmployeesTitle: 'Добавить сотрудника',
    colleagues_fired: 'Уволенные',
    colleagues_exportExcel: 'Экспорт в Exce',
    colleagues_employeeID: 'Номер сотрудника - id',
    colleagues_iin: 'ИИН сотрудника',
    colleagues_fullName: 'ФИО сотрудника',
    colleagues_dateBirth: 'Дата рождения сотрудника',
    colleagues_employeePosition: 'Должность сотрудника',
    colleagues_employeePhone: 'Сотовый телефон сотрудника',
    colleagues_employeeEmail: 'Электронная почта сотрудника',
    colleagues_employeePhoto: 'Фото сотрудника',
    colleagues_startDate: 'Дата начало работы',
    colleagues_endDate: 'Дата окончания работы',
    colleagues_dismissal: 'Увольнение',
    colleagues_working: 'Работающий',
    colleagues_modalRequired: 'Обязательное для заполнения',
    colleagues_employeesErrorLoad: 'Не удалось загрузить cотрудники',
    colleagues_dismissedErrorLoad: 'Не удалось загрузить уволенные',
    colleagues_errorLoadMeta: 'Не удалось загрузить метаданные',
    contingentPassport_employeesAdd: 'Подтверждение cотрудника',
    colleagues_employeesAddSuccess: 'Успешно сохранено',
    colleagues_employeesAddError: 'Ошибка при добавление',
    colleagues_confirmYes: 'Подтверждаю',
    colleagues_organization: 'Организация (подразделение)',
    colleaguesPassport_requiredField: 'Обязательное для заполнения',
    colleagues_group: 'Группа',
    colleagues_noGroup: 'Нет группа',
    colleagues_groupName: 'Наименование группа',
    colleagues_save: 'Сохранить',
    colleagues_dismissalBtn: 'Увольнение',
    colleaguesPassport_correctValue: 'ИИН должен состоять из 12 цифр без символов и пробелов',
    colleaguesPassport_сonfirmationDismissal: 'Подтверждение увольнение',
    colleaguesPassport_successfullyConfirmed: 'Успешно подтверждена',
    colleaguesPassport_enrollError: 'Ошибка при подтверждении о увольнение',
    colleaguesPassport_numberEmployees: 'Количество:',
    colleagues_reportTitle: 'Табель учета посещаемости cотрудников',
    colleagues_tableFor: 'табель для',
    colleagues_reportModalTitle: 'Основание отсутствия',
    colleagues_reportCause: 'Причина',
    colleagues_date: 'Дата',
    colleagues_completionDate: 'Дата завершения',
    colleagues_saveBtn: 'Cохранить',
    colleagues_reference: 'Справка',
    colleagues_export_btn: 'Экспортировать',
    colleagues_export_select_date: 'Выберите период для экспорта',
    colleagues_export_start_date: 'Дата от',
    colleagues_export_end_date: 'Дата до',
    colleagues_reportExportError: 'Не удалось экспортировать табель',
    colleagues_reportErrorLoadTable: 'Не удалось загрузить табель',
    colleagues_causeAddSuccess: 'Успешно сохранено',
    colleagues_causeAddError: 'Ошибка при сохранение',
    colleagues_location: 'Населенный пункт',
    colleagues_organizations: 'Организация',
    colleaguesPassport_errorСurrentEmployees: 'Сотрудник с данным ИИН {{staffIin}} уже зачислен в организацию.',
    colleagues_organizationsNotFound: 'По запросу ничего не найдено',
    colleagues_qr: 'Может генерировать QR',
    colleagues_yes: 'да',
    colleagues_no: 'нет'
  },
  kk: {
    colleagues_employeesAdd: 'Қосу',
    colleagues_modalEmployeesTitle: 'Қызметкер қосу',
    colleagues_modalRequired: 'Міндетті өріс',
    colleagues_confirmYes: 'Растаймын',
    colleagues_organization: 'Ұйым (бөлімше)',
    colleaguesPassport_requiredField: 'Толтыру үшін міндетті',
    colleagues_title: 'Персонал',
    colleagues_employees: 'Қызметкерлер',
    colleagues_dismissed: 'Жұмыстан шыққандар',
    colleagues_fired: 'Жұмыстан шықты',
    colleagues_exportExcel: 'Экспорт в Exce',
    colleagues_employeeID: 'Қызметкер номері',
    colleagues_iin: 'Қызметкер ЖСН-і',
    colleagues_fullName: 'Қызметкер аты-жөні',
    colleagues_dateBirth: 'Қызметкер туған жылы',
    colleagues_employeePosition: 'Қызметкердің лауазымы',
    colleagues_employeePhone: 'Қызметкер ұялы телефоны',
    colleagues_employeeEmail: 'Қызметкер поштасы',
    colleagues_employeePhoto: 'Фото сотрудника',
    colleagues_startDate: 'Жұмыстың бастау күні',
    colleagues_endDate: 'Жұмыстың аяқталу күні',
    colleagues_dismissal: 'Жұмыстан шықты',
    colleagues_working: 'Жұмыс істейді',
    colleagues_employeesErrorLoad: 'Қызметкерлерді жүктеу мүмкін болмады',
    colleagues_dismissedErrorLoad: 'Жұмыстан шыққан қызметкерлер жүктелмеді',
    colleagues_errorLoadMeta: 'Метадеректер жүктелмеді',
    contingentPassport_employeesAdd: 'Қызметкерді растау',
    colleagues_employeesAddSuccess: 'Сәтті сақталды',
    colleagues_employeesAddError: 'Қосу кезінде қате',
    colleagues_group: 'Топтар',
    colleagues_noGroup: 'Топтар жоқ',
    colleagues_groupName: 'Топ аты',
    colleagues_save: 'Сақтау',
    colleagues_dismissalBtn: 'Жұмыстан шығару',
    colleaguesPassport_correctValue: 'ЖСН символдар мен бос орындарсыз 12 саннан тұруы тиіс',
    colleaguesPassport_enrollError: 'Жұмыстан босатуды растау кезіндегі қате',
    colleaguesPassport_successfullyConfirmed: 'Сәтті расталды',
    colleaguesPassport_сonfirmationDismissal: 'Жұмыстан босатуды растау',
    colleaguesPassport_numberEmployees: 'Cаны:',
    colleaguesPassport_errorСurrentEmployees: 'ЖСН-і {{iin}} қызметкер ұйымға қабылданып үлгерді',
    colleagues_reportTitle: 'Қызметкерлердің қатысуын есепке алу табелі',
    colleagues_tableFor: 'кесте',
    colleagues_reportModalTitle: 'Болмау негізі',
    colleagues_reportCause: 'Себебі',
    colleagues_date: 'Күні',
    colleagues_completionDate: 'Аяқталу күні',
    colleagues_saveBtn: 'Сақтау',
    colleagues_reference: 'Анықтама',
    colleagues_export_btn: 'Экспорттау',
    colleagues_export_select_date: 'Экспорттауға арналған кезеңді таңдаңыз',
    colleagues_export_start_date: 'Кезеңнің басы',
    colleagues_export_end_date: 'Кезеңнің соңы',
    colleagues_reportExportError: 'Табельді экспорттау мүмкін болмады',
    colleagues_reportErrorLoadTable: 'Табельді жүктеу мүмкін болмады',
    colleagues_causeAddSuccess: 'Cәтті сақталды',
    colleagues_causeAddError: 'Сақтау кезінде қате',
    colleagues_location: 'Аймақ',
    colleagues_organizations: 'Ұйым',
    colleagues_organizationsNotFound: 'Сұранысыңыз бойынша ақпарат табылмады',
    colleagues_qr: 'QR құра алады',
    colleagues_yes: 'иә',
    colleagues_no: 'жоқ'
  }
});
