import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {DocumentApi} from '../../_helpers/service';
import { Box } from '@material-ui/core';

export default function ShowDocument({ data }) {
  const [urlFile, setUrlFile] = useState('')

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (data?.absenceReasonDocumentName) {
      DocumentApi.openFile(data?.absenceReasonDocumentLink)
        .then(response => {
          setUrlFile(URL.createObjectURL(response.data));
        });
    }
  }, [data?.absenceReasonDocumentName]);

  return (
    <Box>
      {urlFile &&
        <>
          <h3 className='center'>{t('pageReport_reference')}</h3>
          <div className={'center'}>
            <div>
              {
                data.absenceReasonDocumentFormat === 'application/pdf' &&
                <iframe src={urlFile} style={{
                  width: '500px',
                  height: '400px'
                }}></iframe>
              }
              {
                ['image/jpeg', 'image/jpg', 'image/png'].includes(data.absenceReasonDocumentFormat) &&
                <img src={urlFile} alt={t('pageReport_reference')} style={{maxWidth: '100%'}}/>
              }
            </div>
            <Box style={{
              marginTop: '20px'
            }}>
              <a href={urlFile} download
                 style={{fontWeight: 700 }}>{t('pageReport_downloadReference')}</a>
            </Box>
          </div>
        </>
      }
    </Box>
  );
}
