import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    groupPage_data: "Данные о группе",
    groupsPage_information: "Сведения о группе",
    groupPage_academicYear: "Учебный год",
    groupsPage_ageGroup: 'Возрастная группа',
    groupPage_yearGueue: 'Период рождения детей',
    groupPage_nameGroup: 'Наименование детской группы',
    groupPage_language: 'Язык обучения',
    groupPage_stateOrder: 'Госзаказ',
    groupPage_save: 'Сохранить',
    groupPage_saveFormSuccess: 'Данные успешно сохранены',
    groupPage_errorSaveForm: 'Не удалось сохранить данные',
    groupPage_errorLoadMeta: 'Не удалось загрузить мета данных',
    groupPage_errorLoadGroup: 'Не удалось загрузить группy',
    groupPage_errorLoadEducators: 'Не удалось загрузить воспитателей',
    groupPage_educators: 'Воспитатель',
    groupPage_noEducators: 'Нет воспитателя',
    groupPage_chooseСaregiver: 'Выберите воспитателя',
  },
  kk: {
    groupPage_data: "Топ туралы мәліметтер",
    groupsPage_information: "Топ туралы мәліметтер",
    groupPage_academicYear: "Оқу жылы",
    groupsPage_ageGroup: 'Жас тобы',
    groupPage_yearGueue: 'Балалардың туу кезеңі',
    groupPage_nameGroup: 'Балалар тобының атауы',
    groupPage_language: 'Оқыту тілі',
    groupPage_stateOrder: 'Мемлекеттік тапсырыс',
    groupPage_save: 'Сақтау',
    groupPage_saveFormSuccess: 'Деректер сәтті сақталды',
    groupPage_errorSaveForm: 'Деректерді сақтау сәтсіз аяқталды',
    groupPage_errorLoadGroup: 'Топты жүктеу мүмкін болмады',
    groupPage_errorLoadEducators: 'Тәрбиешілерді жүктеу мүмкін болмады',
    groupPage_educators: 'Тәрбиешілер',
    groupPage_noEducators: 'Тәрбиешілер жоқ',
    groupPage_chooseСaregiver: 'Тәрбиешіні таңдаңыз',
    groupPage_errorLoadMeta: 'Мета деректер жүктелмеді',
  }
});