import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    userModal_create: 'Создать пользователя',
    userModal_update: 'Редактировать пользователя',
    userModal_iin: 'ИИН',
    userModal_lastName: 'Фамилия',
    userModal_firstName: 'Имя',
    userModal_middleName: 'Отчество',
    userModal_username: 'Логин',
    userModal_password: 'Пароль',
    userModal_email: 'Email',
    userModal_role: 'Роль',
    userModal_region: 'Регион',
    userModal_services: 'Услуги (перечень услуг)',
    userModal_organization: 'Организация (подразделение)',
    userModal_ruPosition: 'Должность (на русском)',
    userModal_kkPosition: 'Должность (на казахском)',
    userModal_phoneNumber: 'Номер телефона',
    userModal_cancel: 'Отмена',
    userModal_save: 'Сохранить',
    userModal_validationRequired: 'Обязательно для заполнения',
    userModal_validationWrongIin: 'Неправильный формат ИИН',
    userModal_passwordMin8: 'Пароль должен состоять из не менее 8 символов',
    userModal_passwordNotCyrillic: 'Пароль не должен включать кириллицу',
    userModal_validationNotCyrillic: 'Неправильный формат ИИН',
    userModal_validationWrongEmail: 'Некорректный email',
    userModal_editProfile: 'Редактировать профиль',
    userModal_changePassword: 'Изменить пароль',
    userModal_currentPassword: 'Текущий пароль',
    userModal_newPassword: 'Новый пароль',
    userModal_confirmationPassword: 'Повторите пароль',
    userModal_creationDt: 'Дата регистрации',
    userModal_lastLoginDt: 'Время последнего входа',
    userModal_lastPasswordChange: 'Время последней смены пароля',
    userModal_lastFailedAttemp: 'Дата последней не успешной попытки авторизации',
    userModal_validationLogin: 'В поле не должен быть кириллица и символ',
  },
  kk: {
    userModal_create: 'Пайдаланушыны құру',
    userModal_update: 'Пайдаланушыны өңдеу',
    userModal_iin: 'ЖСН',
    userModal_lastName: 'Тегі',
    userModal_firstName: 'Аты',
    userModal_middleName: 'Әкесінің аты',
    userModal_username: 'Логин',
    userModal_password: 'Құпия сөз',
    userModal_email: 'Email',
    userModal_role: 'Рөлі',
    userModal_region: 'Регион',
    userModal_services: 'Услуги (перечень услуг)',
    userModal_organization: 'Ұйым (бөлімше)',
    userModal_ruPosition: 'Лауазымы (орыс тілінде)',
    userModal_kkPosition: 'Лауазымы (қазақ тілінде)',
    userModal_phoneNumber: 'Телефон нөмірі',
    userModal_cancel: 'Болдырмау',
    userModal_save: 'Сақтау',
    userModal_validationRequired: 'Толтыру үшін міндетті',
    userModal_validationWrongIin: 'ЖСН форматы дұрыс емес',
    userModal_passwordMin8: 'Құпия сөз кемінде 8 таңба болуы тиіс',
    userModal_passwordNotCyrillic: 'Құпия сөзге кириллица қолданбау қажет',
    userModal_validationNotCyrillic: 'ЖСН форматы дұрыс емес',
    userModal_validationWrongEmail: 'Қате email',
    userModal_editProfile: 'Профильді өзгерту',
    userModal_changePassword: 'Құпия сөзді өзгерту',
    userModal_currentPassword: 'Ағымдағы құпия сөз',
    userModal_newPassword: 'Жаңа құпия сөз',
    userModal_confirmationPassword: 'Құпия сөзді қайталаңыз',
    userModal_creationDt: 'Тіркеу күні',
    userModal_lastLoginDt: 'Соңғы кіру уақыты',
    userModal_lastPasswordChange: 'Құпия сөзді соңғы ауыстыру уақыты',
    userModal_lastFailedAttemp: 'Соңғы сәтсіз авторизация әрекетінің күні',
    userModal_validationLogin: 'Өрісте кириллица және таңба болмауы керек',
  }
});
