import { addTranslation } from '../../_helpers/Translate';
import MonitoringVisitPage from '../../pages/MonitoringVisitPage/MonitoringVisitPage';

addTranslation({
  ru: {
    breadcrumbs_home: 'Главная',
    breadcrumbs_journal: 'Журнал заявлений',
    breadcrumbs_contactSupport: 'Обращение в службу технической поддержки',
    breadcrumbs_archive: 'Архив',
    breadcrumbs_serviceProviders: 'Реестр услугодателей',
    breadcrumbs_userManagement: 'Управление пользователями',
    breadcrumbs_monitoring: 'Отчеты',
    breadcrumbs_queue: 'Очередь',
    breadcrumbs_monitoringQueue: 'Мониторинг очередей',
    breadcrumbs_instruction: 'Видео инструкция пользователя',
    breadcrumbs_manual: 'Руководство пользователя',
    breadcrumbs_handbooks: 'Справочники',
    breadcrumbs_myTask: 'Мои задачи',
    breadcrumbs_task: 'Задачи',
    breadcrumbs_faq: 'Часто задаваемые вопросы',

    breadcrumbs_kezekArchiv: 'Архив очередей',
    breadcrumbs_kezekBulletin: 'Бюллетень свободных мест',
    breadcrumbs_kezekContingent: 'Контингент',
    breadcrumbs_kezekDirectionList: 'Список направлении',
    breadcrumbs_kezekDirection: 'Направление',
    breadcrumbs_kezekPassport: 'Паспорт',
    breadcrumbs_kezekRequest: 'Запросы на отзыв места',
    breadcrumbs_kezekStopList: 'Стоп лист',
    breadcrumbs_contingen: 'Контингент ДДО',
    breadcrumbs_contingentPassport: 'Паспорт',
    breadcrumbs_colleagues: 'Персонал',
    breadcrumbs_colleaguesPassport: 'Паспорт',
    breadcrumbs_contingentSchool: 'Контингент школьники',
    breadcrumbs_contingentSection: 'Контингент доп образование',
    breadcrumbs_report: 'Табель',
    breadcrumbs_reportFood: 'Табель питание',
    breadcrumbs_monitoringVisit: 'Мониторинг посещения ДДО',
  },
  kk: {
    breadcrumbs_home: 'Басты бет',
    breadcrumbs_journal: 'Өтініштер журналы',
    breadcrumbs_contactSupport: 'Техникалық қолдау қызметіне жүгіну',
    breadcrumbs_archive: 'Архив',
    breadcrumbs_serviceProviders: 'Қызмет көрсетушілердің тізілімі',
    breadcrumbs_userManagement: 'Пайдаланушыларды басқару',
    breadcrumbs_monitoring: 'Есептер',
    breadcrumbs_queue: 'Кезек',
    breadcrumbs_monitoringQueue: 'Кезекті бақылау',
    breadcrumbs_instruction: 'Пайдаланушының бейне нұсқаулығы',
    breadcrumbs_manual: 'Пайдаланушы нұсқаулығы',
    breadcrumbs_handbooks: 'Анықтамалар',
    breadcrumbs_myTask: 'Менің міндеттерім',
    breadcrumbs_task: 'Тапсырмалар',
    breadcrumbs_faq: 'Жиі қойылатын сұрақтар',

    breadcrumbs_kezekArchiv: 'Кезек мұрағаты',
    breadcrumbs_kezekBulletin: 'Бос орындар бюллетені',
    breadcrumbs_kezekContingent: 'Шартты',
    breadcrumbs_kezekDirection: 'Жолдамалар',
    breadcrumbs_kezekDirectionList: 'Жолдамалар тізімі',
    breadcrumbs_kezekPassport: 'Төлқұжат',
    breadcrumbs_contingen: 'МДҰ контингенті',
    breadcrumbs_contingentPassport: 'Төлқұжат',
    breadcrumbs_kezekRequest: 'Орындарды қайтарып алуға сұраныс',
    breadcrumbs_kezekStopList: 'Тоқтату тізімі',
    breadcrumbs_colleagues: 'Қызметкерлер',
    breadcrumbs_colleaguesPassport: 'Төлқұжат',
    breadcrumbs_contingentSchool: 'Оқушылар контингенті',
    breadcrumbs_contingentSection: 'Қосымша білім беру контингенті',
    breadcrumbs_report: 'Табель',
    breadcrumbs_reportFood: 'Тамақтану табелі',
    breadcrumbs_monitoringVisit: 'ДДҰ-ға бару мониторингі',
  }
});
