import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    sidebar_myTask: 'Мои задачи',
    sidebar_journal: 'Журнал',
    sidebar_archive: 'Архив',
    sidebar_monitoring: 'Отчеты',
    sidebar_queue: 'Мониторинг очередей',
    sidebar_handbook: 'Справочники',
    sidebar_serviceProviders: 'Реестр услугодателей',
    sidebar_userManagement: 'Управление пользователями',
    sidebar_instruction: 'Инструкции',
    sidebar_kezekDDOBulletin: 'Бюллетень свободных мест',
    sidebar_kezekDDODirection: 'Направления',
    sidebar_kezekDDOContingent: 'Контингент',
    sidebar_kezekDDOPassport: 'Паспорт',
    sidebar_kezekRequest: 'Запросы на отзыв места',
    sidebar_kezekStopList: 'Стоп-лист',
    sidebar_kezekArchive: 'Архив',
    sidebar_videoInstruction: 'Видео инструкция пользователя',
    sidebar_contactSupport: 'Обращение в службу технической поддержки',
    sidebar_manual: 'Руководство пользователя',
    sidebar_faq: 'Часто задаваемые вопросы',
    sidebar_contingent: 'Контингент ДДО',
    sidebar_groups: 'Группы',
    sidebar_class: 'Классы',
    sidebar_colleagues: 'Персонал',
    sidebar_contingentSchool: 'Контингент школьники',
    sidebar_contingentSection: 'Контингент доп образование',
    sidebar_attendances: 'Табель посещения сотрудников',
    sidebar_reportSchool: 'Табель посещения школьников',
    sidebar_reportFood: 'Табель питания',
    sidebar_reportSection: 'Табель посещения учащихся',
    sidebar_reportDDO: 'Табель посещения детей',
    sidebar_monitoringVisit: "Мониторинг посещения ДДО",
  },
  kk: {
    sidebar_myTask: 'Менің міндеттерім',
    sidebar_journal: 'Журнал',
    sidebar_archive: 'Архив',
    sidebar_monitoring: 'Есептер',
    sidebar_queue: 'Кезекті бақылау',
    sidebar_handbook: 'Анықтамалар',
    sidebar_serviceProviders: 'Қызмет көрсетушілердің тізілімі',
    sidebar_userManagement: 'Пайдаланушыларды басқару',
    sidebar_instruction: 'Нұсқаулықтар',
    sidebar_kezekDDOBulletin: 'Бос орындар бюллетені',
    sidebar_kezekDDODirection: 'Жолдамалар',
    sidebar_kezekDDOContingent: 'Контингент',
    sidebar_kezekDDOPassport: 'Төлқұжат',
    sidebar_kezekRequest: 'Орындарды қайтарып алуға сұраныс',
    sidebar_kezekStopList: 'Тоқтату парағы',
    sidebar_kezekArchive: 'Архив',
    sidebar_videoInstruction: 'Пайдаланушының бейне нұсқаулығы',
    sidebar_contactSupport: 'Техникалық қолдау қызметіне жүгіну',
    sidebar_manual: 'Пайдаланушы нұсқаулығы',
    sidebar_faq: 'Жиі қойылатын сұрақтар',
    sidebar_contingent: 'МДҰ контингенті',
    sidebar_groups: 'Топтар',
    sidebar_class: 'Классы',
    sidebar_colleagues: 'Қызметкерлер',
    sidebar_contingentSchool: 'Оқушылар контингенті',
    sidebar_contingentSection: 'Қосымша білім беру контингенті',
    sidebar_attendances: 'Қызметкерлердің келу кестесі',
    sidebar_reportFood: 'Тамақтану кестесі',
    sidebar_reportDDO: 'Балалардың келу кестесі',
    sidebar_reportSchool: 'Оқушылардың келу кестесі',
    sidebar_monitoringVisit: "ДДО-ға баруды бақылау",
  }
});
