import React, {useState} from 'react';
import Modal from '../../_ui/Modal/Modal';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Button from '../../_ui/Button/Button';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DoneIcon from '@material-ui/icons/Done';
import { Color } from '../ThemeProvider/ThemeStyle';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import moment from 'moment';
import DatePicker from '../../_ui/DatePicker/DatePicker';
import { Switch } from '@material-ui/core';
import { ImportContainer } from './ReportStyle';
import ShowDocument from './ShowDocument';
import Notice from '../../utils/Notice';

const defaultPeriod = { start: null, end: null };

export default function ModalReportCard(modalData) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [causeCode, setCauseCode] = useState(modalData.data?.absenceReason);
  const [isErrorCause, setIsErrorCause] = useState(false);
  const [period, setPeriod] = useState(defaultPeriod);
  const [isErrorDate, setIsErrorDate] = useState(false);
  const [file, setFile] = useState({});

  const save = () => {
    if (causeCode) {
      if (causeCode === 'LEAVE_APPLICATION' && !(period.start && period.end)) {
        setIsErrorDate(true);
      } else {
        dispatch(modalData.saveCause({
          causeCode,
          userID: modalData.data?.userID,
          logDate: modalData.data.logDate,
          close: modalData.close,
          period,
          file: file,

        }))
      }
    } else {
      setIsErrorCause(true)
    }
  }
  return (
    <Modal
      title={modalData.data.isVisited ? `${t('pageReport_arrivalDate') + ': ' + moment(modalData.data.logDate).format('DD.MM.YYYY')}` : `${t('pageReport_reportModalTitle') + ': ' + moment(modalData.data.logDate).format('DD.MM.YYYY')}`}
      open
      onClose={modalData.close}
    >
      <DialogContent>
        {
          modalData.data.isVisited === false &&
          <>
            <Box className='flex items-center'>
              <Typography children={t('pageReport_reportCause')} style={{width: '50%'}} />
              <Box
                style={{
                  width: '100%',
                  maxWidth: '100%',
                }}
              >
                <FormControl fullWidth>
                  <MuiSelect value={causeCode} error={isErrorCause}>
                    {
                      modalData.cause.map((item) => {
                        return <MenuItem
                          value={item.code}
                          onClick={() => {
                            setCauseCode(item.code)
                            setIsErrorCause(false)
                          }}
                          key={item.code}
                        >
                          {item[`${i18n.language}_name`]}
                        </MenuItem>
                      })
                    }
                  </MuiSelect>
                  {isErrorCause && <FormHelperText error>{t('pageReport_requiredField')}</FormHelperText>}
                </FormControl>
              </Box>
            </Box>
              <Box className='flex items-center'>
                <Typography children={'Дата'} style={{width: '50%'}} />
                <Box
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      gap: '5px'
                    }}
                  >
                    <DatePicker
                      lang={i18n.language}
                      withoutForm
                      value={period.start}
                      onChange={start => setPeriod({ ...period, start })}
                    />
                    <DatePicker
                      lang={i18n.language}
                      disabled={!period.start}
                      withoutForm
                      minDate={period.start}
                      value={period.end}
                      onChange={end => setPeriod({ ...period, end })}
                    />
                  </Box>
                  {isErrorDate && <FormHelperText error>{t('pageReport_requiredStartEndDate')}</FormHelperText>}
                </Box>
              </Box>
            {
              (modalData.data.id && causeCode) &&
              <Box className='flex items-center'>
                <Typography children={t('pageReport_reportCauseCancel')} style={{width: '50%'}} />
                <Box
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                >
                  <Switch
                    onChange={() => {
                      setCauseCode('REMOVE_ABSENCE_REASON')
                    }}
                    color="primary"
                  />
                </Box>
              </Box> 
            }
            <Box className="flex items-center">
              <Typography children={t('pageReport_reportConfirmationDocument')} style={{ width: '50%' }} />
              <ImportContainer>
                <div id="file-name">{file.name}</div>
                <input
                  id="contained-button-file"
                  type="file"
                  className="display-none"
                  required
                  accept="image/jpeg,image/jpg,image/png,application/pdf"
                  onChange={({ target }) => {
                    if (target.files[0]) {
                      if (['image/jpeg','image/jpg','image/png', 'application/pdf'].includes(target.files[0].type)) {
                        setFile(target.files[0]);
                      } else {
                        Notice.warning(t('pageReport_formatIsNotSupported'));
                      }
                    }
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    text={t('administrationPage_choose')}
                    component="span"
                  />
                </label>
              </ImportContainer>
            </Box>
            <ShowDocument data = {modalData.data} />
            <Box className="flex justify-end pt2">
              <Button
                color="primary"
                style={{ backgroundColor: Color.bgColorSuccess }}
                loading={modalData?.loadingSave}
                icon={<DoneIcon fontSize="small" className="mr1" />}
                text={ t('pageReport_saveBtn') }
                type='submit'
                onClick={() => save()}
              />
            </Box>

          </>
        }
        {
          modalData.data.isVisited === true &&
          <Box className='flex items-center mb2'>
            <Typography children={t('pageReport_scanTime')} style={{width: '50%'}} />
            <Typography children={moment(modalData.data.visitingTime).format('YYYY-MM-DD HH:mm:ss')} style={{width: '50%'}} />
          </Box>
        }
      </DialogContent>
    </Modal>
  );
}
