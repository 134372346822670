import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Box, Paper, Typography } from '@material-ui/core';
import './MonitoringVisitTranslate';
import { useTranslation } from 'react-i18next';
import paths from './../../_helpers/paths';
import { useDispatch, useSelector } from 'react-redux';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { MonitoringVisitColumns } from './MonitoringVisitColumns';
import FilterDatePickerMonth from '../../components/TableFilter/ColumnsFilters/FilterDatePickerMonth';
import FilterTreeSelect from '../../components/TableFilter/ColumnsFilters/FilterTreeSelect/FilterTreeSelect';
import Button from '../../_ui/Button/Button';
import SaveIcon from '@material-ui/icons/Save';
import { createTableCell, getLastDayOfMonth, monthCurrent } from '../../components/PageReport/reportUtils';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import moment from 'moment';
import { currentOrg, exportData, loadData, monitoringVisitModule } from './MonitoringVisitDucks';
import useUser from '../LoginPage/useUser';
import { ROLES } from '../../_helpers/Constants';
import { useHistory } from 'react-router-dom';

export default function MonitoringVisitPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_monitoringVisit'), href: paths.monitoringVisit }
  ];
  const [copyFilter, setCopyFilter] = useState();
  const [days, setDays] = useState([]);
  const getFullYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(new Date().getMonth() + 1));
  const [isAdmin, setIsAdmin] = useState(false);
  const user = useUser();
  useEffect(() => {
    if (user) {
      user.roles.forEach((item) => {
        if (item === ROLES.ES_GODS) {
          setIsAdmin(true)
        }
      })
    }
  }, [user])
  const { nextPage } = useTableFilter(filter => {
    let activeMonth = moment(filter.dateMin).format('MM');
    const arrDays = createTableCell(getLastDayOfMonth(getFullYear, activeMonth), getFullYear, activeMonth);
    if (arrDays.length) {
      setDays(arrDays)
    }
    setCurrentMonth(activeMonth);
    filter['dateMax'] = `${getFullYear}-${activeMonth}-${getLastDayOfMonth(getFullYear, activeMonth)}T00:00:00`
    setCopyFilter({...filter})
    dispatch(loadData(filter));
  }, {
    pageNumber: 1,
    pageSize: 20,
    dateMin: `${getFullYear}-${currentMonth}-01T00:00:00`
  });

  const { data, loadingExport, loading, general } = useSelector(
    state => state[monitoringVisitModule]
  );
  const onExportHandle = () => {
      dispatch(exportData(copyFilter))
  };

  const checkOrg = (org) => {
    dispatch(currentOrg(org))
    history.push(`/report`);
  }

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 2500 }}>
        <div className="flex justify-between items-center">
          <h3>{t('monitoringVisit_title')}</h3>
        </div>
        <div className="flex justify-between items-center mb2">
          <Box className="flex justify-between items-center">
            <Box className="flex justify-between items-center mr2">
              <Typography style={{ fontSize: '12px', width: '50px' }}>{t('monitoringVisit_month')}</Typography>
              <Box style={{ width: '250px' }}>
                <FilterDatePickerMonth
                  name="dateMin"
                  lang={i18n.language}
                  currentMonth={currentMonth}
                />
              </Box>
            </Box>
            {isAdmin && <Box className="flex justify-between items-center mr2">
              <Typography style={{ fontSize: '12px', width: '200px' }}>{t('monitoringVisit_location')}</Typography>
              <FilterTreeSelect
                lookupId="60"
                name="serviceProviderLocation"
                lang={i18n.language}
                filter={item => item.ARM_gu === 'true'}
              />
            </Box>}

            <Typography style={{ marginLeft: '10px'}}>
              {t('monitoringVisit_quantity')} {' '} {data.totalElements}
            </Typography>
          </Box>
          <Box className="flex justify-between items-center">
            <Button
              onClick={onExportHandle}
              icon={<SaveIcon fontSize="small" className="mr1" />}
              text={t('colleagues_export_btn')}
              disabled={loadingExport}
            />
          </Box>
        </div>
        <StaticTablePagination
          loading={loading}
          columns={MonitoringVisitColumns({
            t,
            lang: i18n.language,
            days,
            isAdmin,
            checkOrg
          })}
          sortable={false}
          data={data.content.length ? [...data.content, general] : []}
          totalElements={data.totalElements ? data.totalElements + 1 : data.totalElements}
          onClickLoadMore={() => nextPage()}
        />
      </Paper>
    </>
  );
}
