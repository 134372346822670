import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './AdministrationStyles';
import Button from '../../_ui/Button/Button';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { administrationColumns } from './AdministrationColumns';
import withModal from '../../components/_hoc/withModal';
import {
  administrationModule,
  loadUsers,
  clearState,
  activateUser,
  deleteUser
} from './AdministrationDucks';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import UserModal from './User/UserModal';
import UserDeleteModal from './UserDeleteModal';
import { loginModule } from '../LoginPage/LoginDucks';
import './AdministrationTranslate';
import ImportModal from './ImportModal';

const AdministrationPage = ({
  loadingTable,
  tableData,
  loadUsers,
  clearState,
  modal,
  activateUser,
  deleteUser
}) => {
  const { t, i18n } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [deletableUser, setDeletableUser] = useState(null);
  const { nextPage, reloadPage } = useTableFilter(loadUsers);

  useEffect(() => clearState, [clearState]);

  function onDelete(info, index) {
    setDeletableUser({ info, index });
    setDeleteModalOpen(true);
  }

  return (
    <Wrapper>
      <Paper className="pb3 pt1 px3 mt2">
        <div className="flex justify-between items-center">
          <h3>{t('administrationPage_title')}</h3>
          <div>
           {/* <Button
              style={{marginRight: 5}}
              icon={<ImportExportIcon style={{ marginRight: 5 }} />}
              text={t('administrationPage_import')}
              onClick={() => setImportModalOpen(true)}
            />*/}
            <Button
              icon={<PersonAddIcon style={{ marginRight: 5 }} />}
              text={t('administrationPage_createUser')}
              onClick={() => modal.open({ create: true })}
            />
          </div>
        </div>
        <StaticTablePagination
          columns={administrationColumns({
            t,
            lang: i18n.language,
            activateUser,
            onDelete
          })}
          data={tableData.content}
          sortable={false}
          onClickRow={({ original, index }) => modal.open({ ...original, index })}
          totalElements={tableData.totalElements}
          loading={loadingTable}
          onClickLoadMore={() => nextPage()}
        />
      </Paper>
      {modal.isOpen && <UserModal {...modal} reloadPage={reloadPage} />}
      <UserDeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        data={deletableUser}
        onDelete={deleteUser}
      />
      <ImportModal
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
      />
    </Wrapper>
  );
};

export default connect(
  state => ({
    loadingTable: state[administrationModule].loadingTable,
    tableData: state[administrationModule].tableData,
    tableFilter: state[administrationModule].tableFilter,
    userPermissions: state[loginModule].permissions
  }),
  { loadUsers, clearState, activateUser, deleteUser }
)(withModal(AdministrationPage));
