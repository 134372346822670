import React from 'react';
import FilterDatePicker from '../../../components/TableFilter/ColumnsFilters/FilterDatePicker';
import FilterSelect from '../../../components/TableFilter/ColumnsFilters/FilterSelect';
import FilterInputField from '../../../components/TableFilter/ColumnsFilters/FilterInputField';
import moment from 'moment';

export const RetiredColumns = ({ t, lang }) => {
  return [
    {
      Header: '№',
      accessor: 'rowNumber',
      maxWidth: 50,
      filterable: false
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 60,
      Filter: <FilterInputField name="id" type="number" />
    },
    {
      Header: t('contingentSchool_iinSchool'),
      accessor: 'studentIin',
      Filter: <FilterInputField name="studentIin" type="number" />
    },
    {
      Header: t('contingentSchool_fullName'),
      accessor: 'fullName',
      accessor: 'childFullName',
       Cell: ({ original}) => {
        return (
          <div>
            {original?.studentSurname} {original?.studentName} {original?.studentMiddlename}
          </div>
        )
      },
      Filter: <FilterInputField name="studentFullName" />
    },
    {
      Header: t('contingentSchool_dateBirth'),
      accessor: 'birthday',
      Cell: row => row.original.birthday && moment(row.original.birthday).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="birthday" lang={lang} />,
    },
    {
      Header: t('contingentSchool_class'),
      accessor: 'studentGrade',
      Cell: ({ original: { classTypeName } }) => classTypeName[`${lang}_name`],
      Filter: <FilterSelect name="studentGrade" dictCode="gu_class_type" lang={lang} />
    },
    {
      Header: t('contingentSchool_litera'),
      accessor: 'letter',
      Cell: ({ original: { letterName } }) => letterName[`${lang}_name`],
      Filter: <FilterSelect name="letter" dictCode="39051" lang={lang} />
    },
    {
      Header: t('contingentSchool_dateEnrollment'),
      accessor: 'enrollmentDate',
      Cell: row => moment(row.original.enrollmentDate).format('DD.MM.YYYY'),
      Filter: <FilterDatePicker name="enrollmentDate" lang={lang} />,
    },
    {
      Header: t('contingentSchool_dropoutDate'),
      accessor: 'dropoutDate',
      Cell: row => moment(row.original.dropoutDate).format('DD.MM.YYYY'),
      Filter: false,
    },
    {
      Header: t('contingentSchool_dropoutReason'),
      accessor: 'dropoutReason',
      Filter: false,
    },
  ];
};
