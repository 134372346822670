import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    monitoringVisit_title: "Мониторинг посещения ДДО",
    monitoringVisitList_title: "Мониторинг посещения:",
    monitoringVisit_namingOrg: "Наименование организации",
    monitoringVisit_monthDays: "Месяц по дням",
    monitoringVisit_markedDays: "Отмеченные дни",
    monitoringVisit_workingDays: "Рабочие дни",
    monitoringVisit_month: "Месяц",
    monitoringVisit_location: "Населенный пункт",
    monitoringVisit_totalMarksMade: "Всего отметок сделали",
    monitoringVisit_errorLoadTable: 'Не удалось загрузить данные',
    monitoringVisit_number: 'Номер',
    monitoringVisit_reportExportError: 'Не удалось экспортировать табель',
    monitoringVisit_quantity: 'Количество:',
  },
  kk: {
    monitoringVisit_title: "ДДО-ға баруды бақылау",
    monitoringVisitList_title: "Мониторинг посещения:",
    monitoringVisit_namingOrg: "Ұйым атауы",
    monitoringVisit_monthDays: "Ай сайын",
    monitoringVisit_markedDays: "Белгіленген күндер",
    monitoringVisit_workingDays: "Жұмыс күндері",
    monitoringVisit_month: "Ай",
    monitoringVisit_location: "Елді мекен",
    monitoringVisit_totalMarksMade: "Барлығы жасалған белгілер",
    monitoringVisit_errorLoadTable: 'Деректерді жүктеу мүмкін емес',
    monitoringVisit_number: 'Саны',
    monitoringVisit_reportExportError: 'Табельді экспорттау мүмкін болмады',
    monitoringVisit_quantity: 'Cаны:',
  }
});
