import MonitoringVisitPage from '../pages/MonitoringVisitPage/MonitoringVisitPage';

const paths = {
  homePage: '/',
  loginPage: '/login',
  archivePage: '/archive',
  statementPage: '/statement/:id',
  statementViewPage: '/statementView/:id',
  journalPage: '/journal',
  monitoringTablePage: '/monitoring/table',
  monitoringChartsPage: '/monitoring/charts',
  handbookPage: '/handbook',
  serviceProvidersPage: '/serviceProviders',
  serviceProviderPage: '/serviceProviders/:mode/:id?',
  serviceProviderViewPage: '/serviceProviders/view/:id',
  administrationPage: '/administration',
  instructionPage: '/instruction',
  contactSupportPage: '/contactSupportPage',
  manualPage: '/manualPage',
  faqPage: '/faqPage',
  myTaskPage: '/myTask',
  taskPage: '/task/:id',
  queueTab1Page: '/queue/tab1',
  queueTab2Page: '/queue/tab2',
  queueByName: '/queue/queueByName',
  resetPassPage: '/resetpass/:id?',
  changePass: '/need-change-password',
  kezekBulletinTable: '/kezekBulletinTable',
  kezekBulletin: '/bulletin/:id?',
  kezekDirectionTable: '/kezekDirectionTable',
  kezekDirection: '/direction/:id',
  kezekContingent: '/kezekContingent',
  kezekPassport: '/kezekPassport',
  kezekRequest: '/kezekRequest',
  kezekStopList: '/kezekStopList',
  kezekArchive: '/kezekArchive',
  contingentCandidatePage: '/contingent/candidate',
  contingentEnrolledPage: '/contingent/enrolled',
  contingentExitedPage: '/contingent/exited',
  groupsTabelPage: '/groups',
  groupPage: '/groups/add/:id?',
  groupListPage: '/groups/:id?',
  classPage: '/class',
  classAddPage: '/class/add/:id?',
  classItemPage: '/class/:id?',
  contingentItemPage: '/contingent/item/:id?',
  contingentItemAddPage: '/contingent/item/add',
  employeesPage: '/colleague/employees',
  dismissedPage: '/colleagues/dismissed',
  colleaguesPassportPage: '/colleagues/save',
  colleaguesPassportUpdatePage: '/colleagues/save/:id?',
  contingentPupilsPage: '/contingent/pupils',
  contingentRetiredPage: '/contingent/retired',
  contingentSchoolPage: '/contingent/school',
  contingentSchoolItemPage: '/contingent/school/:id?',
  contingentStudentPage: '/contingent/student',
  contingentDepartPage: '/contingent/depart',
  contingentSectionPage: '/contingent/section',
  contingentSectionItemPage: '/contingent/section/:id?',
  colleaguesReportPage: '/colleagues/report',
  contingentReportSchoolPage: '/contingent/reportSchool',
  contingentReportSectionPage: '/contingent/reportSection',
  contingentReportDDOPage: '/report',
  contingentReportFoodPage: '/contingent/reportFood',
  monitoringVisit: '/monitoring-visit',
  monitoringVisitList: '/monitoring-visit/:id?'
};

export default paths;
