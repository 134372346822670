import React from 'react';
import moment from 'moment';
import FilterInputField from '../../components/TableFilter/ColumnsFilters/FilterInputField';
import { Color } from '../../components/ThemeProvider/ThemeStyle';

const styles = {
  textAlign: 'center',
  alignSelf: 'center'
};
export const MonitoringVisitColumns = ({ t, days, lang, isAdmin, checkOrg }) => {
  let newDays = []
  days && days.forEach(item => {
    newDays.push({
      Header: String(moment(item).format('DD')),
      accessor: item,
      Cell: ({original}) => {
        let currentVisited = null;
        if (original?.visitsInfo) {
          original.visitsInfo.forEach((visitInfo) => {
            if (visitInfo.date === item) {
              currentVisited = visitInfo;
            }
          })
        }
        return (
          <>
            {
              currentVisited?.isMarked === false && <div
                style={{
                  backgroundColor: Color.secondary,
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
              >
              </div>
            }
            {
              currentVisited?.isMarked === true &&
              <div
                style={{
                  backgroundColor: Color.bgColorSuccess,
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
              >
                <span style={{ transform: 'scale(0.55)', display: 'block' }}> + </span>
              </div>
            }
            {
              currentVisited?.percentage && <div
                style={{
                  textAlign: 'center',
                  transform: 'scale(2)',
                  marginTop: 1,
                  display: 'list-item',
                  cursor: 'pointer'
                }}
              >
                <span style={{ transform: 'scale(0.55)', display: 'block', textAlign: 'center' }}> {currentVisited?.percentage.toFixed(2)} </span>
              </div>
            }
          </>
        )
      },
      style: styles,
      Filter: <span></span>
    })
  });
  let daysTable = [
    {
      Header: 'ID',
      accessor: 'serviceProviderId',
      Filter: <FilterInputField name="serviceProviderId" type="number" />,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: t('monitoringVisit_namingOrg'),
      accessor: 'namingOrg',
      Filter: <FilterInputField name={lang === 'ru' ? 'nameRu' : 'nameKk'} />,
      Cell: ({ original}) => {
        return (
          <div style={{fontWeight: original.serviceProviderId ? null : 600}} onClick={() => checkOrg(original)}>
            {
              lang === 'ru' ? original.serviceProviderRuName : original.serviceProviderKkName
            }
          </div>
        )
      },
      maxWidth: 600,
      width: 600,
    },
    ...newDays,
    {
      Header: t('monitoringVisit_markedDays'),
      accessor: 'markedDays',
      Filter: <span></span>,
      maxWidth: 350,
      width: 350,
      style: styles
    },
  ];

  if (isAdmin) {
    daysTable.push({
      Header: t('journalPage_columnLocation'),
      accessor: 'legalLocality',
      maxWidth: 350,
      width: 350,
      filterable: false,
      Cell: ({ original: { location } }) => location?.[`${lang}_name`],

    })
  }
  return daysTable
};
