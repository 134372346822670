import React, { useEffect, useState } from 'react';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { Box, Paper } from '@material-ui/core';
import './MonitoringVisitTranslate';
import { useTranslation } from 'react-i18next';
import paths from './../../_helpers/paths';
import { useDispatch, useSelector } from 'react-redux';
import StaticTablePagination from '../../_ui/Table/StaticTablePaginated';
import { createTableCell, getLastDayOfMonth, monthCurrent } from '../../components/PageReport/reportUtils';
import useTableFilter from '../../components/TableFilter/useTableFilter';
import moment from 'moment';
import { useParams } from 'react-router';
import { ReportColumns } from '../../components/PageReport/ReportColumns';
import { loadDateReport, monitoringVisitModule } from './MonitoringVisitDucks';

export default function MonitoringVisitListPage() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [days, setDays] = useState([]);
  const getFullYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(monthCurrent(new Date().getMonth() + 1));
  const [counterVisitedDays, setCounterVisitedDays] = useState(0);
  const [counterDaysWithoutReason, setCounterDaysWithoutReason] = useState(0);
  const [counterDaysWithReason, setCounterDaysWithReason] = useState(0);
  const [counterWithReasonReference, setCounterWithReasonReference] = useState(0);
  const [counterWithReasonLeaveApplication, setCounterWithReasonLeaveApplication] = useState(0);
  const [counterWithReasonParentStatement, setCounterWithReasonParentStatement] = useState(0);
  const [counterChildDays, setCounterChildDays] = useState(0);
  const [counterVisitSumma, setVisitSumma] = useState(0);

  const { nextPage } = useTableFilter(filter => {
    let activeMonth = moment(filter.dateMin).format('MM');
    const arrDays = createTableCell(getLastDayOfMonth(getFullYear, activeMonth), getFullYear, activeMonth);
    if (arrDays.length) {
      setDays(arrDays)
    }
    setCurrentMonth(activeMonth);
    filter['dateMax'] = `${getFullYear}-${activeMonth}-${getLastDayOfMonth(getFullYear, activeMonth)}T00:00:00`;
    dispatch(loadDateReport(filter));
  }, {
    pageNumber: 1,
    pageSize: 20,
    dateMin: `${getFullYear}-${currentMonth}-01T00:00:00`,
    serviceProviderId: id
  });

  const { report, loading, cause, currentOrgItem } = useSelector(
    state => state[monitoringVisitModule]
  );

  const breadCrumbItems = [
    { name: t('breadcrumbs_home'), href: '/' },
    { name: t('breadcrumbs_monitoringVisit'), href: paths.monitoringVisit },
    { name: i18n.language === 'ru' ? currentOrgItem.serviceProviderRuName : currentOrgItem.serviceProviderKkName, href: paths.monitoringVisit }
  ];

  useEffect(() => {
    let visitedDays = 0;
    let withoutReason = 0;
    let withReason = 0;
    let withReasonReference = 0;
    let withReasonLeaveApplication = 0;
    let withReasonParentStatement = 0;
    let childDays = 0;
    let visitSumma = 0;
    report.content.forEach((item) => {
      setCounterVisitedDays(visitedDays += item.visitedDays);
      setCounterDaysWithoutReason(withoutReason += item.unvisitedDaysWithoutReason);
      setCounterDaysWithReason(withReason += item.unvisitedDaysWithReason);
      setCounterWithReasonReference(withReasonReference += item.unvisitedDaysWithReasonReference);
      setCounterWithReasonLeaveApplication(withReasonLeaveApplication += item.unvisitedDaysWithReasonLeaveApplication);
      setCounterWithReasonParentStatement(withReasonParentStatement += item.unvisitedDaysWithReasonParentStatement);
      setCounterChildDays(childDays += item.childDays);
      setVisitSumma(((visitSumma += parseFloat(item.visitSumma.toFixed(2))) * 100) / 100);
    })
  }, [report]);

  return (
    <>
      <BreadCrumbs items={breadCrumbItems} />
      <Paper className="pb3 pt1 px3" style={{ minWidth: 2500 }}>
        <div className="flex justify-between items-center">
          <h3>{t('monitoringVisitList_title')} {i18n.language === 'ru' ? currentOrgItem.serviceProviderRuName : currentOrgItem.serviceProviderKkName}</h3>
        </div>
        <Box className='reportСard'>
          <StaticTablePagination
            sortable={false}
            loading={loading}
            columns={ReportColumns({
              t,
              lang: i18n.language,
              days,
              cause
            })}
            data={report.content.length ? [...report.content, {
              name: t('pageReport_total'),
              visitedDays: counterVisitedDays,
              totalFinish: true,
              unvisitedDaysWithoutReason: counterDaysWithoutReason,
              unvisitedDaysWithReason: counterDaysWithReason,
              unvisitedDaysWithReasonReference: counterWithReasonReference,
              unvisitedDaysWithReasonLeaveApplication: counterWithReasonLeaveApplication,
              unvisitedDaysWithReasonParentStatement: counterWithReasonParentStatement,
              childDays: counterChildDays,
              visitSumma: counterVisitSumma,
            }] : []}
            totalElements={report.totalElements ? report.totalElements + 1 : report.totalElements}
            onClickLoadMore={() => nextPage()}
          />
        </Box>
      </Paper>
    </>
  );
}
