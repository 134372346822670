import { addTranslation } from '../../_helpers/Translate';
import config from '../../_helpers/config';

addTranslation({
  ru: {
    loginPage_auth: 'АВТОРИЗАЦИЯ В ТАБЕЛЬ',
    loginPage_username: 'Имя пользователя',
    loginPage_password: 'Пароль',
    loginPage_signIn: 'Войти',
    loginPage_login: 'Логин',
    loginPage_withSignature: 'С помощью ЭЦП',
    loginPage_signInWithSignature: 'Войти с ЭЦП',
    loginPage_nameValidationNotCyrillic: 'Только латинские буквы и цифры',
    loginPage_userNameRequired: 'Обязательно для заполнения',
    loginPage_passwordRequired: 'Обязательно для заполнения',
    loginPage_ecpNotice: 'Всем пользователям АРМ ГУ необходимо получить Электронную цифровую подпись (ЭЦП) в течение месяца. Вход в АРМ ГУ будет производиться с использованием ЭЦП.',
    loginPage_disclaimer:
      'Уведомление об ответственности\n' +
    `Я, пользователь системы "Автоматизация государственных услуг" ${config.orgName.ru}, продолжая работу в системе, подтверждаю свое согласие об ответственности за все осуществленные действия, в соответствии с Законом РК "О персональных данных и их защите"`,

    loginDucks_authError: 'Не удалось авторизоваться',
    loginDucks_logoutError: 'Не удалось выйти из системы',
    loginDucks_incorrectUsernamePassword: 'Введен неверный логин или пароль',
    loginDucks_userNotFound:
      'Неправильное имя пользователя или пароль. Попробуйте ещё раз',
    loginDucks_multiSession_not_allowed:
      'Под учётной записью может работать только 1 пользователь',
    loginDucks_userNotActive: 'Учетная запись не активирована',
    loginDucks_signatureVerificationFail:
      'Ошибка ЭЦП, сертификат просрочен или некорректная подпись',
    loginDucks_help: 'Помощь',
    loginDucks_faq: 'Часто задаваемые вопросы',
    loginDucks_securityReqs: 'Требования к информационной безопасности',
    loginDucks_securityReqs_title:
      'В связи с требованиями обеспечения информационной безопасности:',
    loginDucks_securityReqs_text_1:
      'срок действия пароля 60 дней, для недопущения блокировки учётной записи необходимо сменить пароль до истечения срока',
    loginDucks_securityReqs_text_2:
      'при смене пароля запрещено использование 3-х последних введённых пароля;',
    loginDucks_securityReqs_text_3:
      'после 3-х неудачных попыток авторизации учетная запись пользователя будет заблокирована;',
    loginDucks_securityReqs_text_4:
      'невозможно авторизоваться под одной учетной записью нескольким пользователям;',
    loginDucks_securityReqs_text_5:
      'запрещено передавать свой логин и пароль другим пользователям',
    loginDucks_forgotPass: 'Забыли пароль?',
    loginDucks_passwordAttemptsExceeded:
      'Учетная запись пользователя заблокирована из-за неверно введенного логин/пароля.',
    loginDucks_passwordIsExpired:
      'В связи с истечением срока действия пароля Ваша учетная запись деактивирована',
    loginDucks_passwordPolicy:
      'До истечения пароля осталось {{xDay}} дней. \nПароль можно изменить по ссылке : ',
    loginDucks_passwordPolicy0Day:
      'Сегодня истекает срок действия вашего пароля. \nПароль можно изменить по ссылке : ',
    loginDucks_needChangePassword: 'Сегодня истекает срок действия вашего пароля.',
    loginDucks_multisessionNotAllowed:
      'Пользователь под данной учетной записью уже авторизован',
    loginDucks_newPasswordIsEqualsToPrevious:
      'Новый пароль равен одному из предыдущих или текущему',
    loginDucks_notAbleReceive: '1-го июня в 00-00 все бюллетени с учебным годом 2023-2024 будут отправлены в архив. Услугополучатели не смогут получить направление по этим бюллетеням. Также учебный год поменяется на 2024-2025 в паспорте зачисленных контингентов и группах. Для зачисленных детей в контингенте и групп возрастные группы поменяются на следующую, если возрастная группа 1-го года и 2-х, 3-х, 4-х лет. Остальным нужно поменять в ручную.'
  },
  kk: {
    loginPage_auth: 'АВТОРИЗАЦИЯЛАУ',
    loginPage_username: 'Пайдаланушы аты',
    loginPage_password: 'Құпия сөз',
    loginPage_signIn: 'Кіру',
    loginPage_login: 'Логин',
    loginPage_withSignature: 'ЭЦҚ арқылы',
    loginPage_signInWithSignature: 'ЭЦҚ-мен кіру',
    loginPage_nameValidationNotCyrillic: 'Тек латын әріптері мен сандары',
    loginPage_userNameRequired: 'Толтыру үшін міндетті',
    loginPage_passwordRequired: 'Толтыру үшін міндетті',
    loginPage_ecpNotice: 'АЖО-ның барлық пайдаланушылары бір ай ішінде электрондық цифрлық қолтаңбаны (ЭЦҚ) алуы қажет. АЖО - ға кіру ЭСҚ арқылы жүзеге асырылады',
    loginPage_disclaimer:
      'Жауапкершілік туралы хабарлама\n' +
    `Мен, ${config.orgName.kk} "Мемлекеттік қызметтерді автоматтандыру" пайдаланушысы жүйеде жұмысымды жалғастыра отырып, Қазақстан Республикасының Заңына сәйкес жасалған барлық әрекеттер үшін жауапкершілік туралы келісімді растаймын.`,

    loginDucks_authError: 'Авторизациядан  өту мүмкін емес',
    loginDucks_logoutError: 'Жүйеден шығу мүмкін емес',
    loginDucks_incorrectUsernamePassword: 'Логин немесе құпия сөз қате енгізілді',
    loginDucks_userNotFound: 'Пайдаланушы аты немесе құпиясөз қате. Қайталап көріңіз',
    loginDucks_multiSession_not_allowed:
      'Тіркеулік жазба бойынша тек 1 пайдаланушы жұмыс істей алады',
    loginDucks_userNotActive: 'Тіркеулік жазба қосылмаған',
    loginDucks_signatureVerificationFail:
      'ЭЦҚ қатесі, сертификат мерзімі өткен немесе қол қате',
    loginDucks_help: 'Көмек',
    loginDucks_faq: 'Жиі қойылатын сұрақтар',
    loginDucks_securityReqs: 'Ақпараттық қауіпсіздік талаптары',
    loginDucks_securityReqs_title:
      'Ақпараттық қауіпсіздікті қамтамасыз етуге қойылатын талаптарға байланысты:',
    loginDucks_securityReqs_text_1:
      'пароль 60 күнге жарамды, есептік жазбаны бұғаттауды болдырмау үшін жарамдылық мерзімі аяқталғанға дейін парольді өзгерту қажет',
    loginDucks_securityReqs_text_2:
      'парольді ауыстырған кезде соңғы енгізілген 3 парольді қолдануға тыйым салынады;',
    loginDucks_securityReqs_text_3:
      'авторизацияның 3 сәтсіз әрекетінен кейін пайдаланушы тіркелгісі құлыпталады;',
    loginDucks_securityReqs_text_4:
      'бірнеше пайдаланушылар үшін бір есептік жазбаға кіру мүмкін емес;',
    loginDucks_securityReqs_text_5:
      'логин мен парольді басқа пайдаланушыларға беруге тыйым салынады',
    loginDucks_forgotPass: 'Құпия сөзді ұмыттыңыз ба?',
    loginDucks_passwordAttemptsExceeded:
      'Есептік жазба/құпиясөз 3 рет сәтсіз еңгізілген үшін пайдаланушы тіркелгісі бұғатталды',
    loginDucks_passwordIsExpired:
      'Құпиясөздің қолданылу мерзімінің аяқталуына байланысты Сіздің тіркеу жазбаңыз сөндірілді',
    loginDucks_passwordPolicy:
      'Құпиясөздің бітуіне {{xDay}} күн қалды. \nҚұпиясөзді сілтеме бойынша өзгертуге болады : ',
    loginDucks_passwordPolicy0Day:
      'Құпия сөздің мерзімі бүгін аяқталады. \nҚұпиясөзді сілтеме бойынша өзгертуге болады : ',
    loginDucks_needChangePassword: 'Құпия сөздің мерзімі бүгін аяқталады. ',
    loginDucks_multisessionNotAllowed: 'Көрсетілген пайдаланушы жүйеге кіріп отыр',
    loginDucks_newPasswordIsEqualsToPrevious:
      'Жаңа құпиясөз ағымдағы немесе алдыңғы құпиясөздердің біріне тең',
    loginDucks_notAbleReceive: '1 маусым күні сағат 00-00-де 2023-2024 оқу жылы бар барлық бюллетеньдер мұрағатқа жіберіледі. Қызмет алушылар бұл бюллетеньдерді пайдаланып жолдама ала алмайды. Сондай-ақ, тіркелген контингенттер мен топтарда оқу жылы 2024-2025 жылға ауысады. Егер тіркелген контингенттер мен топтарда жас тобы 1,2,3,4 жас болса, келесі жас тобына ауысады. Қалған жас топтарын қолмен өзгерту керек.'
  }
});
