import sortReducers from '../utils/sortReducers';
import loginReducer, { loginModule } from '../pages/LoginPage/LoginDucks';
import statementReducer, { statementModule } from '../pages/StatementPage/StatementDucks';
import journalReducer, { journalModule } from '../pages/JournalPage/JournalDucks';
import serviceProvidersReducer, {
  serviceProvidersModule
} from '../pages/ServiceProvidersPage/ServiceProvidersDucks';
import serviceProviderReducer, {
  serviceProviderModule
} from '../pages/ServiceProviderPage/ServiceProviderDucks';
import responsibleReducer, {
  responsibleModule
} from '../pages/JournalPage/Responsible/ResponsibleDucks';
import administrationReducer, {
  administrationModule
} from '../pages/AdministrationPage/AdministrationDucks';
import monitoringReducer, {
  monitoringModule
} from '../pages/MonitoringPage/MonitoringDucks';
import handbookReducer, { handbookModule } from '../pages/HandbookPage/HandbookDucks';
import archiveReducer, { archiveModule } from '../pages/ArchivePage/ArchiveDucks';
import myTaskReducer, { myTaskModule } from '../pages/MyTaskPage/MyTaskDucks';
import assignExecutorReducer, {
  assignExecutorModule
} from '../pages/MyTaskPage/TaskPage/AssignExecutor/AssignExecutorDucks';
import questionnaireTaskReducer, {
  questionnaireTaskModule
} from '../pages/MyTaskPage/TaskPage/QuestionnaireTask/QuestionnaireTaskDucks';
import signTaskReducer, {
  signTaskModule
} from '../pages/MyTaskPage/TaskPage/SignTask/SignTaskDucks';
import queueReducer, { queueModule } from '../pages/QueuePage/QueueDucks';
import bpReducer, {
  bpModule
} from '../pages/StatementPage/BusinessProcess/BusinessProcessDucks';
import selectUserReducer, {
  selectUserModule
} from '../pages/MyTaskPage/TaskPage/SelectUser/SelectUserDucks';
import resetPassReducer, {
  resetPassModule
} from '../pages/ResetPassword/ResetPasswordDucks';
import badGatewayReducer, {
  badGatewayModule
} from '../components/BadGateway/BadGatewayDucks';

import kezekArchiveReducer, {kezekArchiveModule} from '../pages/KezekArchivePage/KezekArchiveDucks'
import kezekBulletinTableReducer, {kezekBulletinTableModule} from '../pages/KezekBulletinTablePage/KezekBulletinTableDucks'
import kezekBulletinReducer, {kezekBulletinModule} from '../pages/KezekBulletinPage/KezekBulletinDucks'
import kezekContingentReducer, {kezekContingentModule} from '../pages/KezekContingentPage/KezekContingentDucks'
import kezekDirectionTableReducer, {kezekDirectionTableModule} from '../pages/KezekDirectionTablePage/KezekDirectionTableDucks'
import kezekDirectionReducer, {kezekDirectionModule} from '../pages/KezekDirectionPage/KezekDirectionDucks'
import kezekPassportReducer, {kezekPassportModule} from '../pages/KezekPassportPage/KezekPassportDucks'
import kezekRequestReducer, {kezekRequestModule} from '../pages/KezekRequestPage/KezekRequestDucks'
import kezekStopListReducer, {kezekStopListModule} from '../pages/KezekStopListPage/KezekStopListDucks'
import contingentReducer, {contingentModule} from '../pages/ContingentPage/ContingentDucks'
import contingentPassportReducer, {contingentPassportModule} from '../pages/ContingentPassportPage/ContingentPassportDucks'
import groupsTableReducer, {groupsTableModule} from '../pages/GroupsTablePage/GroupsTableDucks'
import groupPassportReducer, {groupPassportModule} from '../pages/GroupPassportPage/GroupPassportDucks'
import groupListReducer, {groupListModule} from '../pages/GroupListPage/GroupListDucks'
import colleaguesReducer, {colleaguesModule} from '../pages/ColleaguesPage/ColleaguesDucks'
import contingentSchoolReducer, {contingentSchoolModule} from '../pages/ContingentSchoolPage/ContingentSchoolDucks'
import contingentSectionReducer, {contingentSectionModule} from '../pages/ContingentSectionPage/ContingentSectionDucks'
import classPageReducer,{ classPageModule } from '../pages/ClassPage/ClassDucks';
import classPassportReducer, { classPassportModule } from '../pages/ClassPassportPage/ClassPassportDucks';
import classListReducer,{ classListModule } from '../pages/ClassListPage/ClassListDucks';
import monitoringVisitReducer,{monitoringVisitModule } from '../pages/MonitoringVisitPage/MonitoringVisitDucks';

export default sortReducers({
  [loginModule]: loginReducer,
  [statementModule]: statementReducer,
  [journalModule]: journalReducer,
  [serviceProvidersModule]: serviceProvidersReducer,
  [serviceProviderModule]: serviceProviderReducer,
  [responsibleModule]: responsibleReducer,
  [administrationModule]: administrationReducer,
  [monitoringModule]: monitoringReducer,
  [handbookModule]: handbookReducer,
  [archiveModule]: archiveReducer,
  [myTaskModule]: myTaskReducer,
  [assignExecutorModule]: assignExecutorReducer,
  [questionnaireTaskModule]: questionnaireTaskReducer,
  [signTaskModule]: signTaskReducer,
  [queueModule]: queueReducer,
  [bpModule]: bpReducer,
  [selectUserModule]: selectUserReducer,
  [resetPassModule]: resetPassReducer,
  [badGatewayModule]: badGatewayReducer,

  [kezekArchiveModule]: kezekArchiveReducer,
  [kezekBulletinTableModule]: kezekBulletinTableReducer,
  [kezekBulletinModule]: kezekBulletinReducer,
  [kezekContingentModule]: kezekContingentReducer,
  [kezekDirectionTableModule]: kezekDirectionTableReducer,
  [kezekDirectionModule]: kezekDirectionReducer,
  [kezekPassportModule]: kezekPassportReducer,
  [kezekRequestModule]: kezekRequestReducer,
  [kezekStopListModule]: kezekStopListReducer,
  [contingentModule]: contingentReducer,
  [contingentPassportModule]: contingentPassportReducer,

  [groupsTableModule]: groupsTableReducer,
  [groupPassportModule]: groupPassportReducer,
  [groupListModule]: groupListReducer,

  [classPageModule]: classPageReducer,
  [classPassportModule]: classPassportReducer,
  [classListModule]: classListReducer,


  [colleaguesModule]: colleaguesReducer,
  
  [contingentSchoolModule]: contingentSchoolReducer,
  [contingentSectionModule]: contingentSectionReducer,
  [monitoringVisitModule]: monitoringVisitReducer
});
